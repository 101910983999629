import React from "react"
import PropTypes from "prop-types"
import Button from "highline/components/button"
import { CheckIcon } from "highline/components/icons"
import classNames from "classnames"

import styles from "highline/styles/components/auth/user_auth_side_section.module.css"

const UserAuthSideSection = ({ className, isSignInForm, onClick, isDrawerComponent }) => (
  <div
    className={classNames(
      "component",
      "user-auth-side-section-component",
      styles.component,
      isDrawerComponent ? null : styles.rightBorder,
      className,
      { [styles.centerAlign]: !isSignInForm }
    )}
  >
    <h2 className={isDrawerComponent ? styles.drawerHeading : styles.desktopTabletHeading}>
      {isSignInForm ? "Join Bonobos" : "Already have an account?"}
    </h2>
    <h2 className={styles.mobileHeading}>{isSignInForm ? "OR" : "Already have an account?"}</h2>

    {isSignInForm && (
      <div className={styles.desktopTabletInfo}>
        <span className={styles.subHeading}>
          Create an account to start enjoying exclusive member benefits
        </span>
        <ul>
          <li>
            <CheckIcon className={styles.icon} /> {` `}
            20% off your first purchase
          </li>
          <li>
            <CheckIcon className={styles.icon} /> {` `}
            VIP early access to products
          </li>
          <li>
            <CheckIcon className={styles.icon} /> {` `}
            Easy exchanges and faster checkout
          </li>
        </ul>
      </div>
    )}
    <Button onClick={onClick} className={styles.btn} layout="primary-outline">
      {isSignInForm ? "Create New Account" : "Sign In"}
    </Button>
  </div>
)

UserAuthSideSection.propTypes = {
  className: PropTypes.string,
  isDrawerComponent: PropTypes.bool,
  isSignInForm: PropTypes.bool,
  onClick: PropTypes.func,
}

UserAuthSideSection.defaultProps = {
  className: "",
  isDrawerComponent: false,
  onClick: () => {},
}

export default UserAuthSideSection
