import React, { useState } from "react"
import { useRouter } from "next/router"
import PropTypes from "prop-types"
import {
  toggleExistingUserLogin,
  authInputChanged,
  inlineValidation,
  loginAsync,
  registerAsync,
  recoverPasswordAsync,
  userAlreadyLoggedIn,
} from "highline/redux/actions/auth_actions"
import classNames from "classnames"
import SignIn from "highline/components/auth/sign_in"
import SignUp from "highline/components/auth/sign_up"
import ResetPassword from "highline/components/auth/reset_password"
import UserAuthSideSection from "highline/components/auth/user_auth_side_section"
import styles from "highline/styles/components/auth/user_auth.module.css"
import { useDispatch, useSelector } from "react-redux"
import {
  useSelectUserEmail,
  useSelectIsLoading,
  useSelectError,
  useSelectUseSignInForm,
  useSelectFirstName,
  useSelectIsLoggedIn,
  useSelectLastName,
  useSelectCcpaEmail,
  useSelectHideForms,
  useSelectShouldShowResetPasswordPrompts,
  useSelectResetPasswordSuccess,
} from "highline/selectors/auth/auth_selectors"

const UserAuth = ({ className, signInOverride, layout, redirectOnSuccessUrl }) => {
  const { query } = useRouter()
  const dispatch = useDispatch()
  const [password, setPassword] = useState("")

  const disabled = useSelector(useSelectIsLoading)
  const email = useSelector(useSelectUserEmail) ?? ""
  const error = useSelector(useSelectError) ?? new Map()
  const useSignInForm = useSelector(useSelectUseSignInForm)
  const firstName = useSelector(useSelectFirstName)
  const lastName = useSelector(useSelectLastName)
  const isLoggedIn = useSelector(useSelectIsLoggedIn)
  const ccpaEmail = useSelector(useSelectCcpaEmail) ?? ""
  const hideForms = useSelector(useSelectHideForms) ?? false
  const shouldShowResetPasswordPrompts = useSelector(useSelectShouldShowResetPasswordPrompts)
  const resetPasswordSuccess = useSelector(useSelectResetPasswordSuccess)

  function handleIsLoggedIn() {
    dispatch(userAlreadyLoggedIn())
  }

  if (isLoggedIn && layout === "page") {
    handleIsLoggedIn()

    return null
  }

  const isNewMember = query?.new_member === "true"
  const renderSignInForm = signInOverride ? signInOverride === "sign-in" : useSignInForm

  function handleInputChange(e) {
    e.preventDefault()

    if (e.target.name !== "password") {
      dispatch(authInputChanged(e.target.name, e.target.value))
    } else {
      setPassword(e.target.value)
    }
  }

  function handleInfoLinkClick(e) {
    e.preventDefault()
    dispatch(toggleExistingUserLogin())
  }

  function handleToggleExistingUserLogin(e) {
    dispatch(toggleExistingUserLogin())
  }

  function handlePasswordReset(e) {
    e.preventDefault()
    dispatch(recoverPasswordAsync())
  }

  function getPassword({ target }) {
    return [...target.elements].find((element) => element.id === "password")?.value
  }

  function handleSignInSubmit(e) {
    e.preventDefault()

    const passwordValue = getPassword(e)

    dispatch(loginAsync(passwordValue, redirectOnSuccessUrl))
  }

  function handleSignUpSubmit(e) {
    e.preventDefault()

    const passwordValue = getPassword(e)

    if (!passwordValue) {
      return
    }

    dispatch(registerAsync(passwordValue, redirectOnSuccessUrl))
  }

  function handleInputValidation(name, errorMessage) {
    dispatch(inlineValidation(name, errorMessage))
  }

  let component

  if (shouldShowResetPasswordPrompts)
    component = (
      <ResetPassword
        disabled={disabled}
        error={error}
        shouldShowResetPasswordPrompts={shouldShowResetPasswordPrompts}
        onInputChange={handleInputChange}
        onSubmit={handlePasswordReset}
        resetPasswordSuccess={resetPasswordSuccess}
        inputValue={email}
      />
    )
  else if (!isNewMember && renderSignInForm)
    component = (
      <SignIn
        disabled={disabled}
        email={email}
        password={password}
        onInputChange={handleInputChange}
        onInputValidation={handleInputValidation}
        onPasswordReset={handlePasswordReset}
        onSubmit={handleSignInSubmit}
        error={error}
        ccpaEmail={ccpaEmail}
        className={styles.form}
      />
    )
  else
    component = (
      <SignUp
        disabled={!!ccpaEmail || disabled}
        onInputChange={handleInputChange}
        onSubmit={handleSignUpSubmit}
        email={email}
        firstName={firstName}
        showCheckoutPrompt={!!signInOverride}
        lastName={lastName}
        password={password}
        error={error}
        onInfoLinkClick={handleInfoLinkClick}
        onInputValidation={handleInputValidation}
        className={styles.form}
      />
    )

  return (
    <div
      className={classNames(
        "component",
        "user-auth-component",
        styles[layout],
        styles.component,
        className
      )}
    >
      {!hideForms && (
        <UserAuthSideSection
          isDrawerComponent={layout === "drawer"}
          isSignInForm={renderSignInForm}
          onClick={handleToggleExistingUserLogin}
        />
      )}
      {hideForms ? null : component}
    </div>
  )
}

UserAuth.propTypes = {
  className: PropTypes.string,
  redirectOnSuccessUrl: PropTypes.string,
  layout: PropTypes.oneOf(["page", "drawer"]),
  signInOverride: PropTypes.oneOf(["sign-in", "sign-up"]),
}

UserAuth.defaultProps = {
  layout: "page",
  redirectOnSuccessUrl: "",
}

export default UserAuth
